.container {
  margin: 100px 20px 0 230px;
  max-width: 70%;
  box-sizing: border-box;
  box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 40px;
}

.selectField {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: var(--gray);
  margin-bottom: 30px;
  border: 2px solid var(--red);
  border-radius: 12px;
  outline: none;
  background: transparent;
}

.addBeerHeader {
  text-align: center;
  margin-bottom: 20px;
}

.selectBeerProp {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: var(--gray);
  margin-bottom: 30px;
  border: none;
  border-bottom: 2px solid var(--red);
  outline: none;
  background: transparent;
}

.brandBox {
  position: relative;
}

.inputField {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: var(--black);
  margin-bottom: 30px;
  border: none;
  border-bottom: 2px solid var(--red);
  outline: none;
  background: transparent;
}

.beerLabel {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: var(--black);
  pointer-events: none;
  transition: top 0.5s, left 0.5s, color 0.5s, font-size 0.5s;
}

.inputField:focus ~ .beerLabel,
.inputField:valid ~ .beerLabel {
  top: -20px;
  left: 0;
  color: var(--black);
  font-size: 12px;
}

.checkboxContainer {
  display: flex;
  align-items: flex-start;
  align-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 15px;
}

.optionCheckbox:checked + .checkboxLabel {
  background: var(--red);
  color: var(--background);
}
.checkboxLabel {
  font-size: 16px;
  color: var(--gray);
  background: var(--yellow);
  padding: 8px 15px;
  border-radius: 12px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  text-transform: lowercase;
  font-weight: 400;
  cursor: pointer;
}

.optionCheckbox {
  display: none;
}

#addBeerButton {
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: 600;
  padding: 15px 30px;
  background-color: var(--yellow);
  box-shadow: 0 10px 20px -8px var(--grey);
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 12px;
}

#addBeerButton:hover {
  box-shadow: 0 10px 20px -4px var(--grey);
  text-shadow: var(--grey) 1px 0 3px;
}
.buttonBox {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.empty {
  display: block;
  width: 100%;
  height: 120px;
}

.noBrandQuestion {
  display: flex;
  gap: 1rem;
  margin: 2rem;
}
.brandQuestion {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.brandQuestion h2{
  text-align: center;
  margin: 40px;
}

.navigation {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.inputHidden {
  display: none;
}

.customInputFile {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 600;
  padding: 15px 30px;
  background-color: var(--yellow);
  box-shadow: 0 8px 8px -8px var(--grey);
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 12px;
}

.customInputFile:hover {
  background-color: var(--red);
}

.fileNameDisplay {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  font-size: 0.8rem;
  color: var(--grey);
}

@media screen and (max-width: 720px) {
  .container {
    margin: 100px 20px 0 120px;
    max-width: none;
    padding: 25px;
  }
}
@media screen and (max-width: 520px) {
  .container {
    margin: 100px 20px 0 105px;
    padding: 15px;
  }
  .addBeerHeader {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  .brandQuestion h2 {
    font-size: 1rem;
    margin: 20px;
  }
  .checkboxContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    gap: 1.5rem;
    margin: 15px 0 15px 0;
  }
}