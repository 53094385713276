.toggle {
  display: block;
  width: 28px;
  height: 30px;
  margin: 30px auto 10px;
}

.toggle span:after,
.toggle span:before {
  content: '';
  position: absolute;
  left: 0;
  top: -9px;
}

.toggle span:after {
  top: 9px;
}

.toggle span {
  position: relative;
  display: block;
}

.toggle span,
.toggle span:after,
.toggle span:before {
  width: 100%;
  height: 5px;
  background-color: var(--grey);
  transition: all 0.3s;
  backface-visibility: hidden;
  border-radius: 2px;
}

.toggle.on span {
  background-color: transparent;
}
.toggle.on span:before {
  transform: rotate(45deg) translate(5px, 5px);
}
.toggle.on span:after {
  transform: rotate(-45deg) translate(7px, -8px);
}
.toggle.on + .menu {
  opacity: 1;
  visibility: visible;
}

.menu {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 100px;
  color: var(--grey);
  width: 200px;
  padding: 10px;
  text-align: center;
  border-radius: 12px;
  background: var(--background);
  box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
  transition:ease-out 0.4s;
}
.menu:after {
  position: absolute;
  top: -25px;
  right: 10px;
  content: '';
  display: block;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 30px solid var(--background);
}

.list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login {
  background-color: var(--yellow);
  padding: 7px 30px;
  margin: 9px 15px 0 15px;
  color: var(--grey);
  font-size: 1.4rem;
  font-weight: 700;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.3s;
}
.login:hover {
  text-shadow: rgb(100, 100, 100) 1px 0 1px;
  box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.7);
}
