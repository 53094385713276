.container {
  margin: 100px 0 0 220px;
  box-sizing: border-box;
  border-radius: 12px;
}
.controlPanel {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.buttons {
  display: flex;
  justify-content: center;
}
.searchResults {
  margin-left: 80px;
}
.showBtn {
  font-size: 1rem;
  font-weight: 600;
  padding: 15px 30px;
  margin: 0px 10px;
  background-color: var(--yellow);
  box-shadow: 0 10px 20px -8px var(--grey);
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 12px;
}
.searchBar {
  display: flex;
  justify-content: center;
}

.searchBtn {
  font-size: 1rem;
  font-weight: 600;
  padding: 15px 30px;
  margin: 0px 10px;
  background-color: var(--yellow);
  box-shadow: 0 10px 20px -8px var(--grey);
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 12px;
}

.searchInput {
  width: 250px;
  padding: 10px;
  font-size: 16px;
  color: var(--gray);
  border: 2px solid var(--red);
  border-radius: 12px;
  outline: none;
  background: transparent;
}

.listAllBrands {
  display: flex;
}

.empty {
  height: 90px;
}
@media screen and (max-width: 1120px) {
  .controlPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    gap: 1.5rem;
  }
  .container {
    padding: 0 20px 0 0;
  }
}
@media screen and (max-width: 720px) {
  .controlPanel {
    margin: 0;
    padding-top: 20px;
  }
  .container {
    padding-left: 0;
    padding-top: 0;
    margin-left: 125px;
  }
  .searchResults {
    margin: 0;
    width: 100%;
  }
}
@media screen and (max-width: 555px) {
  .controlPanel {
    margin: 0;
    padding-top: 0;
  }
  .searchBar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
  }
  .searchInput {
    width: 90%;
    font-size: 13px;
  }
  .buttons {
    flex-direction: column;
    gap: 16px;
  }
  .searchResults {
    margin: 0 0 0 0px;
    width: 100%;
  }
}
