.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 100px;
}
.addButtonBox {
  margin-top: 15%;
  display: flex;
  justify-content: space-evenly;
  max-width: 60%;
}

.addButton {
  font-size: 1rem;
  font-weight: 600;
  padding: 15px 30px;
  background-color: var(--yellow);
  box-shadow: 0 10px 20px -8px var(--grey);
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 12px;
  margin: 20px;
}

.btnBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  margin: 10px;
  border-radius: 12px;
  width: 50vh;
  box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 840px) {
  .addButtonBox {
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
    text-align: center;
  }
  .addButton {
    margin: 10px;
  }
  .btnBox {
    width: 100%;
  }
}
