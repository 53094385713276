.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.waveSection {
  width: 100%;
}
.waves {
  position: relative;
}
.wave {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
}
#wave1 {
  opacity: 1;
  bottom: 0;
  z-index: 1000;
  background: url(wave3.png);
  background-size: 1000px 100px;
  animation: animate2 5s linear infinite;
}

#wave2 {
  opacity: 0.5;
  bottom: 10px;
  z-index: 999;
  background: url(wave2.png);
  background-size: 1000px 100px;
  animation: animate 2s linear infinite;
}
#wave3 {
  opacity: 0.7;
  bottom: 15px;
  z-index: 998;
  background: url(wave1.png);
  background-size: 1000px 100px;
  animation: animate2 4s linear infinite;
}
#wave4 {
  opacity: 0.6;
  bottom: 15px;
  z-index: 999;
  background: url(wave2.png);
  background-size: 1000px 100px;
  animation: animate2 2s linear infinite;
}
#wave5 {
  opacity: 0.7;
  bottom: 0;
  z-index: 999;
  background: url(wave1.png);
  background-size: 1000px 100px;
  animation: animate 4s linear infinite;
}
@keyframes animate {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-position-x: 0;
  }
}
@keyframes animate2 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1000px;
  }
}

.textSection {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1001;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.textElement {
  padding: 10px;
  font-size: 1.2rem;
  font-family: 'Atkinson Hyperlegible', sans-serif;
}

@media screen and (max-width: 660px) {
  .textElement {
    font-size: 1rem;
  }
}
