.mainLayout {
  font-size: 62.5%;
  box-sizing: border-box;
  min-width: 320px;
  display: flex;
  font-family: 'Atkinson Hyperlegible', sans-serif;
}

.greeting {
  position: absolute;
  left: 25%;
  top: 15%;
  font-size: 8rem;
}

.question {
  display: block;
  text-align: start;
  position: absolute;
  right: 15%;
  left: 25%;
  top: 35%;
  font-size: 3.5rem;
  font-weight: 700;
  color: var(--grey);
}
.beer {
  font-weight: bolder;
  color: var(--red);
}

/* responsive design */
@media screen and (max-width: 940px), screen and (max-height: 900px) {
  .greeting {
    font-size: 6rem;
    margin-left: 15%;
    margin-top: 5%;
  }
  .question {
    font-size: 2.5rem;
    margin-left: 10%;
    right: 10%;
    text-align: end;
  }
}
@media screen and (max-width: 660px) {
  .greeting {
    font-size: 3rem;
    margin-left: 9%;
    margin-right: 3%;
    margin-top: 7%;
    text-align: center;
  }
  .question {
    font-size: 1.5rem;
    margin-left: 10%;
    top: 30%;
    right: 10%;
  }
}
@media screen and (max-width: 380px) {
  .greeting {
    font-size: 2.5rem;
    margin-left: 60px;
    text-align: right;
    margin-top: 5%;
    margin-right: 10px;
  }
  .question {
    top: 30%;
  }
}
