.brandCardContainer {
  margin: 0 10px 0 10px;
  padding: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 100%;
  box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.brandCardContainer h2 {
  margin: 10px 0;
}
.brandCardContainer p {
  margin: 5px;
}
.element {
  margin: 5px 0 0 40px;
}
@media screen and (max-width: 720px) {
  .brandCardContainer {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .element {
    margin: 100px 0 0 0;
  }
}
