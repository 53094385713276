.card {
  display: flex;
  align-items: center;
  max-width: 650px;
  border-radius: 12px;
  text-align: center;
  padding: 24px;
  margin: 10px;
  background-color: var(--cards-bg);
  box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.15);
}
.image {
  max-width: 280px;
  width: 32vw;
  height: 270px;
  object-fit: cover;
  margin-left: -60px;
  margin-right: 20px;
  border-radius: inherit;
}
.card h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--red);
}

.card h5 {
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 20px;
  color: var(--black);
}

.card p {
  font-size: 14px;
  font-weight: 400;
  color: var(--grey);
  margin-bottom: 5px;
}
.options {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.options p {
  margin: 0px 6px;
}
.text {
  overflow: hidden;
}

@media (width <= 720px) {
  .card {
    margin: 16px 20px;
    padding: 0 20px 20px 20px;
    flex-direction: column;
  }
  .image {
    margin: -100px 0 30px 0;
    width: 100%;
  }
  .text {
    width: 100%;
  }
}
@media screen and (max-width: 520px) {
  .card {
    margin: 10px;
    padding: 0 10px 10px 10px;
  }
  .image {
    margin: -100px 0 0 0;
  }
}
