.allBeersContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.element {
  margin: 20px -7px 0 -30px;
}
@media screen and (max-width: 720px) {
  .element {
    margin-top: 95px;
    margin-left: -20px;
  }
}
