.container {
  margin-left: -80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.element{
  margin: 20px 20px 10px 40px;
}
@media screen and (max-width: 720px) {
  .container {
    width: 100%;
    margin: 0;
  }
  .element{
    width: 100%;
    margin: 20px 0px 0px 5px;
  }
}