.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.message {
  margin-bottom: 10px;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
}

.confirmButton,
.cancelButton {
  margin-left: 10px;
}
