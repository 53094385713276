.container {
  width: 100%;
  margin: 10px 0 0 -80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.brandElement {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
}
.beerElement {
  margin-left: 60px;
}

@media screen and (max-width: 720px) {
  .beerElement {
    width: 100%;
    margin: 90px -10px 0 0;
  }
  .container {
    width: 100%;
    margin: 10px 0 0 0px;
  }
}
