.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 135px 30px 0 27%;
}
.randomBeerPage {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.card {
  text-decoration: none;
}
.bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.empty {
  width: 20px;
  height: 90px;
}

@media screen and (max-width: 940px) {
  .container {
    margin: 135px 30px 0 37%;
  }
}
@media screen and (max-width: 720px) {
  .container {
    margin: 185px 10px 0 110px;
  }
}
