.content {
  background: var(--background);
  height: 75px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.btn {
  display: inline-block;
  color: var(--red);
  padding: 28px;
  position: relative;
  letter-spacing: 1px;

  &__circle,
  &__text,
  &__filledCircle {
    position: absolute;
  }

  &__circle {
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 12px;
    width: 100%;
    box-shadow: 0 0 1px 1px var(--yellow);
    transition: 0.3s linear;
  }

  &__filledCircle {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 56px;
    height: 56px;
    border-radius: 12px;
    background: var(--yellow);
    display: flex;
    transition: 0.3s ease-in-out;

    svg {
      width: 36px;
      height: 36px;
      margin: auto;
      fill: var(--grey);
    }
  }

  &__text {
    font-weight: 700;
    font-size: 1rem;
    margin-left: 3px;
    transform: translateY(-50%);
    white-space: nowrap;
    z-index: 0;
    transition: 0.3s linear;
  }

  &:hover {
    .btn__circle {
      transform: scale(0);
    }

    .btn__filledCircle {
      transform: translate(-50%, -50%) scale(1);
    }

    .btn__text {
      transform: translate(30px, -50%);
    }
  }
}
