.switchCheckbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 80px;
  height: 40px;
  background: rgb(85, 96, 103, 0.3);
  border-radius: 80px;
  position: relative;
  transition: background-color 0.3s;
}

.switchLabel .switchButton {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  transition: 0.3s;
  background-image: url('./english-flag.png');
  background-position: center;
  background-size: cover;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  border: 1px solid var(--grey);
}

.switchCheckbox:checked + .switchLabel .switchButton {
  left: calc(100% - 2px);
  transform: translateX(-100%);
  background-image: url('./german-flag.png');
  background-position: center;
  background-size: cover;
  border: 1px solid var(--grey);
}

.switchLabel:active .switchButton {
  width: 60px;
}
