* {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: #f6f8fc;
  height: 100vh;
  z-index: -100;
}
