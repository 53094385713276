* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.menu {
  position: fixed;
  inset: 15% 0 40px 20px;
  background-color: var(--yellow);
  width: 75px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  border-radius: 12px;
  z-index: 100;
}
.menu.active {
  width: 190px;
}
.hamburger {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 23px;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  z-index: -10;
}
.hamburger::before {
  content: '';
  position: absolute;
  width: 30px;
  height: 3px;
  background: var(--red);
  transform: translateY(-8px);
  transition: 0.5s;
}
.menu.active .hamburger::before {
  transform: translateY(0) rotate(45deg);
}
.hamburger::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 3px;
  background: var(--red);
  transform: translateY(8px);
  box-shadow: 0 -8px 0 var(--red);
  transition: 0.5s;
}
.menu.active .hamburger::after {
  transform: translateY(0) rotate(-45deg);
  box-shadow: 0 0px 0 var(--red);
}
.list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.navLink {
  position: relative;
  width: 100%;
  height: 76px;
  border-radius: 12px;
  border: 8px solid transparent;
  transition: 0.5s;
  text-decoration: none;
}
.navLink .text {
  font-size: 1.2rem;
  font-family: 'Atkinson Hyperlegible', sans-serif;
  font-weight: 600;
}
.navLink.active {
  transform: translateX(30px);
  background-color: var(--background);
}
.navLink::before {
  content: '';
  position: absolute;
  top: -28px;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-bottom-right-radius: 20px;
  box-shadow: 6px 5px 0 5px var(--background);
  transform: scale(0);
  transform-origin: bottom right;
  transition: 0.5s;
}
.navLink.active::before {
  right: 22px;
  transform: scale(1);
}

.navLink::after {
  content: '';
  position: absolute;
  bottom: -28px;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-top-right-radius: 20px;
  box-shadow: 6px -3px 0 3px var(--background);
  transform: scale(0);
  transform-origin: bottom right;
  transition: 0.5s;
}
.navLink.active::after {
  right: 22px;
  transform: scale(1);
}
.element {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-align: center;
  z-index: 100;
}
.icon {
  position: relative;
  color: black;
  display: block;
  min-width: 60px;
  height: 60px;
  border-radius: 10px;
  font-size: 2em;
  line-height: 60px;
  border: 6px solid transparent;
  transition: 0.5s;
}
.navLink.active .icon {
  background-color: var(--red);
  color: var(--black);
}
.icon::before {
  content: '';
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--red);
  filter: blur(8px);
  opacity: 0;
  transition: 0.5s;
}
.navLink.active .icon::before {
  opacity: 0.5;
}
.icon::after {
  content: '';
  position: absolute;
  top: 10px;
  left: -60px;
  width: 15px;
  height: 15px;
  background-color: var(--red);
  border: 8px solid var(--background);
  border-radius: 50%;
}
.text {
  position: relative;
  padding: 0 15px;
  color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.menu.active .text {
  visibility: visible;
  opacity: 1;
}
.navLink.active .text {
  color: var(--red);
}

@media screen and (max-width: 720px) {
  .hamburger {
    display: none;
  }
  .menu {
    height: 380px;
  }
  .menu.active {
    width: 75px;
  }
  .text{
    display: none;
  }
}
